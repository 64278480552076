import { PayloadAction } from '@reduxjs/toolkit';
import {AppStateType} from "./index";

// payload
type PayloadType = 'onetime' | 'daily';

// action reducer
export function changePurchaseFrequencyReducer(state: AppStateType, action: PayloadAction<PayloadType>) {
    state.purchaseFrequency = action.payload;
}
