import { combineReducers } from '@reduxjs/toolkit';
import {appReducer, AppStateType} from "./app";

export type AppCombinedState = {
  app?: AppStateType;
};

export const combinedReducer = combineReducers({
  app: appReducer,
});
