import { createSlice } from '@reduxjs/toolkit';
import {changeDeliveryReducer} from "./change-delivery";
import {changeMilkTypeReducer} from "./change-milk-type";
import {changePaymentReducer} from "./change-payment";
import {changePurchaseFrequencyReducer} from "./change-purchase-frequency";

export type AppStateType = {
  milkType?: 'cow' | 'buffalo' | 'mix';
  milkPrice?: number;
  purchaseFrequency?: 'onetime' | 'daily';
  quantity?: number;
  deliveryType?: 'morning' | 'evening';
  paymentType?: 'cash' | 'advance' | 'end of month';
  contact: {
    name?: string;
    phoneNo?: string;
  };
  address: {
    street1?: string;
    street2?: string;
  };
  referralCode?: string;
};

const initialState: AppStateType = {
    milkType: null,
    milkPrice: 0,
    purchaseFrequency: null,
    quantity: 1,
    deliveryType: null,
    paymentType: null,
    contact: {
        name: '',
        phoneNo: ''
    },
    address: {
        street1: '',
        street2: ''
    },
    referralCode: '',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeDeliveryAction: changeDeliveryReducer,
    changeMilkTypeAction: changeMilkTypeReducer,
    changePaymentAction: changePaymentReducer,
    changePurchaseFrequencyAction: changePurchaseFrequencyReducer,
  }
});

export const { changeDeliveryAction, changeMilkTypeAction, changePaymentAction, changePurchaseFrequencyAction } = appSlice.actions;

export const appReducer = appSlice.reducer;
