import { PayloadAction } from '@reduxjs/toolkit';
import {AppStateType} from "./index";
import {MILK_PRICE_BUFFALO, MILK_PRICE_COW, MILK_PRICE_MIX} from "../../utils/contants";

// payload
type PayloadType = 'cow' | 'buffalo' | 'mix';

// action reducer
export function changeMilkTypeReducer(state: AppStateType, action: PayloadAction<PayloadType>) {
    state.milkType = action.payload;
    state.milkPrice = {
        'cow': MILK_PRICE_COW,
        'buffalo': MILK_PRICE_BUFFALO,
        'mix': MILK_PRICE_MIX
    }[action.payload];
}
