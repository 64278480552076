import { PayloadAction } from '@reduxjs/toolkit';
import {AppStateType} from "./index";

// payload
type PayloadType = {
    name?: string;
    phoneNo?: string;
    street1?: string;
    street2?: string;
    referralCode?: string;
};

// action reducer
export function changeDeliveryReducer(state: AppStateType, action: PayloadAction<PayloadType>) {
    state.contact.name = action.payload?.name;
    state.contact.phoneNo = action.payload?.phoneNo;
    state.address.street1 = action.payload?.street1;
    state.address.street2 = action.payload?.street2;
    state.referralCode = action.payload?.referralCode;
}
