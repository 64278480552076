import { PayloadAction } from '@reduxjs/toolkit';
import {AppStateType} from "./index";

// payload
type PayloadType = {
    quantity?: number;
    deliveryType?: 'morning' | 'evening';
    paymentType?: 'cash' | 'advance' | 'end of month';
};

// action reducer
export function changePaymentReducer(state: AppStateType, action: PayloadAction<PayloadType>) {
    if (action.payload.quantity) {
        state.quantity = action.payload.quantity;
    }
    if (action.payload.deliveryType) {
        state.deliveryType = action.payload.deliveryType;
    }

    state.paymentType = action.payload.paymentType ?? state.paymentType;
}
