import {Route, Routes} from "react-router-dom";
import { Provider } from 'react-redux';
import { persist, store } from "./redux/store";
import { PersistGate } from 'redux-persist/lib/integration/react';
import {lazy, Suspense} from "react";
import ProtectedRoute from "./utils/ProtectedRoute";
import PublicRoute from "./utils/PublicRoute";
import { Toaster } from "sonner";

const Home = lazy(() => import("./Home"));
const Dairy = lazy(() => import("./Dairy"));
const Login = lazy(() => import("./Login"));
const Payment = lazy(() => import("./Payment"));
const Delivery = lazy(() => import("./Delivery"));
const Confirmation = lazy(() => import("./Confirmation"));
const PurityReport = lazy(() => import("./PurityReport"));
const AdminOverview = lazy(() => import("./Bo/AdminOverview"));
const ManageExpenses = lazy(() => import("./Bo/ManageExpenses"));
const CreateMilkProduction = lazy(() => import("./Bo/CreateMilkProduction"));
const UpdateMilkProduction = lazy(() => import("./Bo/UpdateMilkProduction"));
const CreateExpense = lazy(() => import("./Bo/CreateExpense"));
const UpdateExpense = lazy(() => import("./Bo/UpdateExpense"));
const SetupCost = lazy(() => import("./Bo/SetupCost"));
const CreateSetupCost = lazy(() => import("./Bo/CreateSetupCost"));
const UpdateSetupCost = lazy(() => import("./Bo/UpdateSetupCost"));
const Lost = lazy(() => import("./Bo/Lost"));
const CreateLost = lazy(() => import("./Bo/CreateLost"));
const UpdateLost = lazy(() => import("./Bo/UpdateLost"));
const ManageCows = lazy(() => import("./Bo/ManageCows"));
const Settings = lazy(() => import("./Bo/Settings"));

const App = () => {
    return (
        <>
            <Toaster richColors={true} />
            <Provider store={store}>
                <PersistGate persistor={ persist }>
                    <Suspense>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/login" element={
                                <PublicRoute>
                                    <Login />
                                </PublicRoute>
                            } />
                            <Route path="/dairy" element={<Dairy />} />
                            <Route path="/payment" element={<Payment />} />
                            <Route path="/delivery" element={<Delivery />} />
                            <Route path="/confirmation" element={<Confirmation />} />
                            <Route path="/purity-report" element={<PurityReport />} />
                            <Route path="/bo/overview" element={
                                <ProtectedRoute>
                                    <AdminOverview />
                                </ProtectedRoute>
                            } />
                            <Route path="/bo/expenses" element={
                                <ProtectedRoute>
                                    <ManageExpenses />
                                </ProtectedRoute>
                            } />
                            <Route path="/bo/create-expense" element={
                                <ProtectedRoute>
                                    <CreateExpense />
                                </ProtectedRoute>
                            } />
                            <Route path="/bo/update-expense" element={
                                <ProtectedRoute>
                                    <UpdateExpense />
                                </ProtectedRoute>
                            } />
                            <Route path="/bo/create-milk-production" element={
                                <ProtectedRoute>
                                    <CreateMilkProduction />
                                </ProtectedRoute>
                            } />
                            <Route path="/bo/update-milk-production" element={
                                <ProtectedRoute>
                                    <UpdateMilkProduction />
                                </ProtectedRoute>
                            } />
                            <Route path="/bo/setup-cost" element={
                                <ProtectedRoute>
                                    <SetupCost />
                                </ProtectedRoute>
                            } />
                            <Route path="/bo/create-setup-cost" element={
                                <ProtectedRoute>
                                    <CreateSetupCost />
                                </ProtectedRoute>
                            } />
                            <Route path="/bo/update-setup-cost" element={
                                <ProtectedRoute>
                                    <UpdateSetupCost />
                                </ProtectedRoute>
                            } />
                            <Route path="/bo/lost" element={
                                <ProtectedRoute>
                                    <Lost />
                                </ProtectedRoute>
                            } />
                            <Route path="/bo/create-lost" element={
                                <ProtectedRoute>
                                    <CreateLost />
                                </ProtectedRoute>
                            } />
                            <Route path="/bo/update-lost" element={
                                <ProtectedRoute>
                                    <UpdateLost />
                                </ProtectedRoute>
                            } />
                            <Route path="/bo/manage-cows" element={
                                <ProtectedRoute>
                                    <ManageCows />
                                </ProtectedRoute>
                            } />
                            <Route path="/bo/settings" element={
                                <ProtectedRoute>
                                    <Settings />
                                </ProtectedRoute>
                            } />
                        </Routes>
                    </Suspense>
                </PersistGate>
            </Provider>
        </>
    )
}

export default App;