import { configureStore, Middleware } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import {AppCombinedState, combinedReducer} from "./reducer";

export type PreloadedStateType = AppCombinedState;

const persistedReducer = persistReducer({
    key: 'root',
    storage,
}, combinedReducer);

const middlewares: Middleware[] = [];

if (typeof window !== 'undefined' && !window.location.href.includes('.com')) {
  middlewares.push(logger as Middleware);
}

function createStore() {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares)
  });
}

export const store = createStore();
export const persist = persistStore(store);

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;