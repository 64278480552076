import React from 'react'
import {Navigate, useLocation} from "react-router-dom"
import {isLoggedIn} from "./jwt";

const PublicRoute = ({children}: {children: any}) => {
    let location = useLocation();

    if(isLoggedIn()) {
        return <Navigate to="/bo/overview" state={{ from: location}} replace />
    }
    return children
};

export default PublicRoute;