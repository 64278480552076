import React from 'react'
import {Navigate, useLocation} from "react-router-dom"
import {isLoggedIn} from "./jwt";

const ProtectedRoute = ({children}: {children: any}) => {
    let location = useLocation();

    if(!isLoggedIn()) {
        return <Navigate to="/login" state={{ from: location}} replace />
    }
    return children
};

export default ProtectedRoute;